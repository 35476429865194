//import './bootstrap';

import {Carousel} from 'flowbite';

window.onload =()=>{
    const m_btn = document.getElementById('mobile-button')
    const m_menu = document.getElementById('mobile-menu')
    const m_container = document.getElementById('campaign-container')
    
    const m_ticket_btn = document.getElementById('open-ticket-list')
    const m_tickets = document.getElementById('ticket-list')
    if(m_btn){
        m_btn.addEventListener('click', ()=>{
            m_menu.classList.toggle('max-h-0')
            m_menu.classList.toggle('max-h-[95vh]')
            m_container.classList.toggle('top-0')
            m_container.classList.toggle('top-[95vh]')
        })
    }
    
    if(m_ticket_btn){
        m_ticket_btn.addEventListener('click', (e)=>{
            e.preventDefault()
            m_tickets.classList.toggle('max-h-[500vh]')
            m_tickets.classList.toggle('max-h-0')
        })
    }

}
